

.moveable-line {
   display: none!important;
}

.moveable-origin {
    display: none!important;
}

.moveable-control {
    position: absolute;
    width: 12px!important;
    height: 12px!important;;
    border-radius: 50%;
    border: 1px solid #403C5D!important;
    box-sizing: border-box;
    background: #47E7A2!important;
    margin-top: -7px;
    margin-left: -7px;
    z-index: 10;
}